import React from 'react';
import { Link } from 'gatsby';

import theme from '../theme/theme';

import Madebytr from '../images/madebytr.inline.svg';

const Nav = props => {
  return (
    <nav
      css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      {...props}
    >
      <Link to="/" css={{ display: 'flex' }}>
        <Madebytr css={{ fill: 'white' }} />
      </Link>
      <Link
        to="/contact"
        css={{
          textDecoration: 'none',
          color: theme.colors.lightest,
          ...theme.text['600'],
          ':hover': {
            color: theme.colors.light,
          },
        }}
      >
        Contact
      </Link>
    </nav>
  );
};

export default Nav;
