import React from 'react';
import { oneOf, node } from 'prop-types';
import theme from '../theme/theme';

const Heading = ({ children, level, size, ...rest }) => {
  const Element = `h${level}`;

  return <Element css={{ ...theme.headings[size] }} {...rest}>{children}</Element>;
};

Heading.propTypes = {
  children: node.isRequired,
  level: oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
  size: oneOf(Object.keys(theme.headings)),
};

Heading.defaultProps = {
  size: '500',
};

export default Heading;
