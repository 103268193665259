import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import emotionReset from 'emotion-reset';

import theme from '../theme/theme';

const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={css`
          ${emotionReset}
        `}
      />
      <Global
        styles={css`
          * {
            box-sizing: border-box;
          }

          html {
            background: ${theme.colors.lightest};
            font-family: ${theme.fontFamilies.ui};
            line-height: 1.15;
            height: 100%;
          }

          body {
            margin: 0;
            font-family: ${theme.fontFamilies.ui};
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: ${theme.colors.lightest};
            text-align: left;
            background-color: ${theme.colors.darkest};
            min-height: 100%;
          }

          em {
            font-style: italic;
          }

          ul {
            list-style: disc;
            margin-left: 30px;
          }
        `}
      />
      <div
        css={{
          // backgroundColor: theme.colors.darkest,
          // margin: 16,
          display: 'flex',
          flexDirection: 'column',
          padding: 16,
          minHeight: '100vh',
          '@media(min-width: 900px)': {
            padding: '48px 64px',
          },
        }}
      >
        {children}
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
