const fontFamilies = {
  ui: `"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  display: `"SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
};

const colors = {
  darkest: '#0A0721',
  dark: '#464452',
  bright: '#9FA6FB',
  lightest: '#FFF',
  light: '#C6C6C6',
  danger: '#EC4C47',
};

const theme = {
  colors,
  fontFamilies,
  text: {
    '600': {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.07px',
      /**
       * Use font family display because the font-size is 20px.
       */
      fontFamily: fontFamilies.display,
    },
    '500': {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.05px',
      fontFamily: fontFamilies.ui,
    },
    '400': {
      // Default
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '-0.05px',
      fontFamily: fontFamilies.ui,
    },
    '300': {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0',
      fontFamily: fontFamilies.ui,
    },
  },
  headings: {
    '900': {
      fontSize: '35px',
      fontWeight: 600,
      lineHeight: '40px',
      letterSpacing: '-0.2px',
      fontFamily: fontFamilies.display,
    },
    '800': {
      fontSize: '29px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '-0.2px',
      fontFamily: fontFamilies.display,
    },
    '700': {
      fontSize: '24px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '-0.07px',
      fontFamily: fontFamilies.display,
    },
    '600': {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.07px',
      fontFamily: fontFamilies.display,
    },
    '500': {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '-0.05px',
      fontFamily: fontFamilies.ui,
    },
    '400': {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '-0.05px',
      fontFamily: fontFamilies.ui,
    },
    '300': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0',
      fontFamily: fontFamilies.ui,
    },
    '200': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',
      letterSpacing: '0',
      fontFamily: fontFamilies.ui,
    },
    '100': {
      fontSize: '11px',
      fontWeight: 400,
      textTransform: 'uppercase',
      lineHeight: '16px',
      letterSpacing: '0.6px',
      fontFamily: fontFamilies.ui,
    },
  },
};

export default theme;
